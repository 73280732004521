// deleteMatch.js (Frontend)
import axios from 'axios';

const deleteMatch = async (id, fetchData, setError) => {
    try {
        await axios.delete(`${process.env.REACT_APP_SERVER}/matches/${id}`);
        await fetchData(); // Reload matches data after deleting a match
    } catch (err) {
        console.error('Error deleting match:', err.response || err.message || err);
        setError(err);
    }
};

export default deleteMatch;
