import axios from 'axios';

const updateBetWin = async (id, betWin, fetchData, setError) => {
    try {
        await axios.put(`${process.env.REACT_APP_SERVER}/matches/${id}/betwin`, { betWin });
        fetchData(); // Reload matches data after updating bet win
    } catch (err) {
        console.error('Error updating bet win:', err.response || err.message || err);
        setError(err);
    }
};

export default updateBetWin;
