// Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/Sidebar.css'; // Optionale CSS-Datei für Styling

const Sidebar = () => {
    const location = useLocation();
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        if (location.pathname.startsWith('/settings')) {
            setShowSettings(true);
        } else {
            setShowSettings(false);
        }
    }, [location.pathname]);

    return (
        <div className="sidebar">
            <ul>
                <li className={location.pathname === '/' ? 'active' : ''}>
                    <Link to="/">Dashboard</Link>
                </li>
                <li className={location.pathname === '/bets' ? 'active' : ''}>
                    <Link to="/bets">Wetten</Link>
                </li>
                <li className={location.pathname === '/matches' ? 'active' : ''}>
                    <Link to="/matches">Spiele</Link>
                </li>
                <li className={location.pathname === '/statistics' ? 'active' : ''}>
                    <Link to="/statistics">Statistiken</Link>
                </li>
                <li>
                    <div
                        onClick={() => setShowSettings(!showSettings)}
                        className={`menu-item ${showSettings ? 'active' : ''}`}
                    >
                        Einstellungen
                    </div>
                    {showSettings && (
                        <ul className="submenu">
                            <li className={location.pathname === '/settings/countries' ? 'active' : ''}>
                                <Link to="/settings/countries">Länder verwalten</Link>
                            </li>
                            <li className={location.pathname === '/settings/leagues' ? 'active' : ''}>
                                <Link to="/settings/leagues">Ligen verwalten</Link>
                            </li>
                            <li className={location.pathname === '/settings/seasons' ? 'active' : ''}>
                                <Link to="/settings/seasons">Seasons verwalten</Link>
                            </li>
                            <li className={location.pathname === '/settings/teams' ? 'active' : ''}>
                                <Link to="/settings/teams">Teams verwalten</Link>
                            </li>
                            <li className={location.pathname === '/settings/matches' ? 'active' : ''}>
                                <Link to="/settings/matches">Spiele verwalten</Link>
                            </li>
                            <li className={location.pathname === '/settings/telegram' ? 'active' : ''}>
                                <Link to="/settings/telegram">Telegram verwalten</Link>
                            </li>
                        </ul>
                    )}
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;
