import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import '../styles/Statistics.css';

const Statistics = () => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        country: '',
        league: '',
        season: '',
    });
    const [countries, setCountries] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/countries`);
                setCountries(response.data);
            } catch (err) {
                setError(err);
            }
        };

        fetchCountries();
    }, []);

    useEffect(() => {
        const fetchLeagues = async () => {
            if (filters.country) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_SERVER}/leagues`, {
                        params: { countryId: filters.country }
                    });
                    setLeagues(response.data);
                    setSeasons([]); // Reset seasons when country changes
                    setFilters(prevFilters => ({ ...prevFilters, league: '', season: '' })); // Reset league and season filters
                } catch (err) {
                    setError(err);
                }
            } else {
                setLeagues([]);
                setSeasons([]);
                setFilters(prevFilters => ({ ...prevFilters, league: '', season: '' }));
            }
        };

        fetchLeagues();
    }, [filters.country]);

    useEffect(() => {
        const fetchSeasons = async () => {
            if (filters.league) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_SERVER}/seasons`, {
                        params: { leagueId: filters.league }
                    });
                    setSeasons(response.data);
                    setFilters(prevFilters => ({ ...prevFilters, season: '' })); // Reset season filter
                } catch (err) {
                    setError(err);
                }
            } else if (filters.country) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_SERVER}/seasons`, {
                        params: { countryId: filters.country }
                    });
                    setSeasons(response.data);
                    setFilters(prevFilters => ({ ...prevFilters, season: '' })); // Reset season filter
                } catch (err) {
                    setError(err);
                }
            } else {
                setSeasons([]);
                setFilters(prevFilters => ({ ...prevFilters, season: '' }));
            }
        };

        fetchSeasons();
    }, [filters.league, filters.country]);

    const fetchStats = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/matches/stats/betswin`, {
                params: filters
            });

            const statsData = response.data;

            let totalH2HWinRate = 0;
            let totalThreeWayWinRate = 0;
            let countH2H = 0;
            let countThreeWay = 0;

            statsData.forEach(stat => {
                if (stat.h2hWinRate > 0) {
                    totalH2HWinRate += stat.h2hWinRate;
                    countH2H++;
                }
                if (stat.directWinRate > 0) {
                    totalThreeWayWinRate += stat.directWinRate;
                    countThreeWay++;
                }
            });

            const overallH2HWinRate = countH2H > 0 ? (totalH2HWinRate / countH2H).toFixed(2) : '0.00';
            const overallThreeWayWinRate = countThreeWay > 0 ? (totalThreeWayWinRate / countThreeWay).toFixed(2) : '0.00';

            const formattedStats = statsData.map(stat => ({
                ...stat,
                h2hWinRate: stat.h2hWinRate.toFixed(2),
                directWinRate: stat.directWinRate.toFixed(2),
            }));

            setStats([
                ...formattedStats,
                {
                    name: 'Gesamt',
                    h2hWinRate: overallH2HWinRate,
                    directWinRate: overallThreeWayWinRate,
                },
            ]);

            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }, [filters]);

    useEffect(() => {
        fetchStats();
    }, [fetchStats]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const getChartData = () => {
        if (!stats.length) {
            return {
                labels: [],
                datasets: [
                    {
                        label: 'H2H Win Rate',
                        data: [],
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    },
                    {
                        label: '3-Wege Win Rate',
                        data: [],
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    },
                ],
            };
        }

        return {
            labels: stats.map(stat => stat.name),
            datasets: [
                {
                    label: 'H2H Win Rate',
                    data: stats.map(stat => parseFloat(stat.h2hWinRate)),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: '3-Wege Win Rate',
                    data: stats.map(stat => parseFloat(stat.directWinRate)),
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    if (loading) return <p className="loading">Loading...</p>;
    if (error) return <p className="error">Error loading data: {error.message}</p>;

    return (
        <div className="statistics-container">
            <h1>Betting Statistics</h1>
            <div className="filters">
                <select name="country" value={filters.country} onChange={handleFilterChange}>
                    <option value="">Land filtern</option>
                    {countries.map(country => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </select>
                <select name="league" value={filters.league} onChange={handleFilterChange} disabled={!filters.country}>
                    <option value="">Liga filtern</option>
                    {leagues.map(league => (
                        <option key={league.id} value={league.id}>{league.name}</option>
                    ))}
                </select>
                <select name="season" value={filters.season} onChange={handleFilterChange} disabled={!filters.league && !filters.country}>
                    <option value="">Saison filtern</option>
                    {seasons.map(season => (
                        <option key={season.id} value={season.id}>{season.name}</option>
                    ))}
                </select>
            </div>
            <div className="chart-container">
                <Bar data={getChartData()} />
            </div>
        </div>
    );
};

export default Statistics;
