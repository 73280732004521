import axios from 'axios';

const handleResultChange = async (id, result, fetchData, setError) => {
    try {
        console.log('Updating result for match ID:', id);
        console.log('New result:', result);

        const updateResultEndpoint = `${process.env.REACT_APP_SERVER}/matches/${id}/result`;
        console.log('Sending result to endpoint:', updateResultEndpoint);
        await axios.put(updateResultEndpoint, { result });

        const matchEndpoint = `${process.env.REACT_APP_SERVER}/matches/${id}`;
        console.log('Fetching match data from endpoint:', matchEndpoint);
        const matchResponse = await axios.get(matchEndpoint);
        const match = matchResponse.data;
        console.log('Match data fetched:', match);

        let homeElo, awayElo;
        if (match.gameday === 1) {
            const homeEloEndpoint = `${process.env.REACT_APP_SERVER}/teams/${match.home_team_id}/elo`;
            const awayEloEndpoint = `${process.env.REACT_APP_SERVER}/teams/${match.away_team_id}/elo`;
            console.log('Fetching home team Elo from endpoint:', homeEloEndpoint);
            console.log('Fetching away team Elo from endpoint:', awayEloEndpoint);

            const homeEloResponse = await axios.get(homeEloEndpoint);
            const awayEloResponse = await axios.get(awayEloEndpoint);

            homeElo = homeEloResponse.data.elo_value;
            awayElo = awayEloResponse.data.elo_value;
        } else {
            const homeEloEndpoint = `${process.env.REACT_APP_SERVER}/matches/elo/${match.home_team_id}/${match.gameday}`;
            const awayEloEndpoint = `${process.env.REACT_APP_SERVER}/matches/elo/${match.away_team_id}/${match.gameday}`;
            console.log('Fetching home team Elo for gameday from endpoint:', homeEloEndpoint);
            console.log('Fetching away team Elo for gameday from endpoint:', awayEloEndpoint);

            const homeEloResponse = await axios.get(homeEloEndpoint);
            const awayEloResponse = await axios.get(awayEloEndpoint);

            homeElo = homeEloResponse.data.elo_value;
            awayElo = awayEloResponse.data.elo_value;
        }

        console.log('Home team Elo:', homeElo);
        console.log('Away team Elo:', awayElo);

        fetchData(); // Reload matches data after updating result
    } catch (err) {
        console.error('Error updating result:', err.response || err.message || err);
        setError(err);
    }
};

export default handleResultChange;
