// App.js
import React from 'react';
import Routing from './components/Routing';

const App = () => {
  return (
      <div className="App">
        <Routing />
      </div>
  );
}

export default App;
