// Routing.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';

import Matches from './pages/Matches';
import Dashboard from "./pages/Dashboard";
import Statistics from "./pages/Statistics";
import Bets from "./pages/Bets";

//Import Settings
import SetMatches from "./pages/settings/SetMatches";
import SetLeagues from "./pages/settings/SetLeagues";
import SetTelegram from "./pages/settings/SetTelegram";
import SetSeasons from "./pages/settings/SetSeasons";
import SetTeams from './pages/settings/SetTeams';
import SetCountries from './pages/settings/SetCountries';

const Routing = () => {
    return (
        <Router>
            <div className="app-container">
                <Sidebar />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/bets" element={<Bets />} />
                        <Route path="/statistics" element={<Statistics />} />
                        <Route path="/matches" element={<Matches />} />
                        <Route path="/settings/countries" element={<SetCountries />} />
                        <Route path="/settings/leagues" element={<SetLeagues />} />
                        <Route path="/settings/teams" element={<SetTeams />} />
                        <Route path="/settings/matches" element={<SetMatches />} />
                        <Route path="/settings/seasons" element={<SetSeasons />} />
                        <Route path="/settings/telegram" element={<SetTelegram />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default Routing;
