import axios from 'axios';

const getMatches = async (setCountries, setLeagues, setSeasons, setTeams, setMatches, setLoading, setError) => {
    try {
        const [countriesResponse, leaguesResponse, seasonsResponse, teamsResponse, matchesResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_SERVER}/countries`),
            axios.get(`${process.env.REACT_APP_SERVER}/leagues`),
            axios.get(`${process.env.REACT_APP_SERVER}/seasons`),
            axios.get(`${process.env.REACT_APP_SERVER}/teams`),
            axios.get(`${process.env.REACT_APP_SERVER}/matches`)
        ]);

        setCountries(countriesResponse.data);
        setLeagues(leaguesResponse.data);
        setSeasons(seasonsResponse.data);
        setTeams(teamsResponse.data);
        setMatches(matchesResponse.data);
        setLoading(false);
    } catch (err) {
        console.error('Error fetching matches data:', err); // Protokolliert den Fehler in der Konsole für Debugging-Zwecke
        if (setError) {
            setError('Fehler beim Abrufen der Daten.');
        }
        setLoading(false);
    }
};

export default getMatches;
