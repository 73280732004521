import axios from 'axios';
import calculateWinChances from './calculateWinChances';

const addMatch = async (newMatchData, fetchData, resetForm, setError, setValidationError) => {
    const { newCountryId, newLeagueId, newSeasonId, newHomeTeamId, newAwayTeamId, matchDate, matchTime, gameday } = newMatchData;

    // Validierung der Eingabedaten
    if (!newCountryId || !newLeagueId || !newSeasonId || !newHomeTeamId || !newAwayTeamId || !matchDate || !gameday) {
        if (typeof setValidationError === 'function') {
            setValidationError('Alle Felder müssen ausgefüllt sein.');
        }
        return;
    }

    if (newHomeTeamId === newAwayTeamId) {
        if (typeof setValidationError === 'function') {
            setValidationError('Heim- und Auswärtsteam müssen unterschiedlich sein.');
        }
        return;
    }

    try {
        let homeChance = null;
        let awayChance = null;

        // Berechnung der Siegchancen, wenn der Spieltag 1 ist
        if (parseInt(gameday) === 1) {
            const homeEloResponse = await axios.get(`${process.env.REACT_APP_SERVER}/teams/${newHomeTeamId}/elo`);
            const awayEloResponse = await axios.get(`${process.env.REACT_APP_SERVER}/teams/${newAwayTeamId}/elo`);

            const homeElo = homeEloResponse.data.elo_value;
            const awayElo = awayEloResponse.data.elo_value;

            const chances = calculateWinChances(homeElo, awayElo);
            homeChance = chances.homeChance * 100;
            awayChance = chances.awayChance * 100;
        }

        const matchData = {
            homeTeamId: newHomeTeamId,
            awayTeamId: newAwayTeamId,
            matchDate,
            matchTime: matchTime || null,
            gameday: parseInt(gameday),
            homeChance,
            awayChance
        };

        // Anlegen des neuen Spiels
        await axios.post(`${process.env.REACT_APP_SERVER}/matches`, matchData);

        await fetchData(); // Aktualisierung der Spiel-Daten nach dem Hinzufügen
        resetForm();
        if (typeof setValidationError === 'function') {
            setValidationError(''); // Zurücksetzen der Validierungsfehler
        }
    } catch (err) {
        console.error('Error adding match:', err.response || err.message || err);
        if (typeof setError === 'function') {
            setError(err); // Setzt den Fehler, falls `setError` eine Funktion ist
        }
    }
};

export default addMatch;
