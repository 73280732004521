import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../../styles/Leagues.css'; // Optionale CSS-Datei für Styling

const SetLeagues = () => {
    const [countries, setCountries] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newLeague, setNewLeague] = useState('');
    const [newCountryId, setNewCountryId] = useState('');
    const [newKValue, setNewKValue] = useState('');
    const [validationError, setValidationError] = useState('');
    const [editLeagueId, setEditLeagueId] = useState(null);
    const [editLeagueName, setEditLeagueName] = useState('');
    const [editCountryId, setEditCountryId] = useState('');
    const [editKValue, setEditKValue] = useState('');
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const timerRef = useRef(null);

    useEffect(() => {
        const fetchCountriesAndLeagues = async () => {
            try {
                const [countriesResponse, leaguesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER}/countries`),
                    axios.get(`${process.env.REACT_APP_SERVER}/leagues`)
                ]);
                setCountries(countriesResponse.data);
                setLeagues(leaguesResponse.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchCountriesAndLeagues();
    }, []);

    const handleAddLeague = async () => {
        if (!newLeague.trim() || !newCountryId || !newKValue) {
            setValidationError('Alle Felder müssen ausgefüllt sein.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/leagues`, { name: newLeague, countryId: newCountryId, k_value: parseInt(newKValue) });
            setLeagues([...leagues, response.data]);
            setNewLeague('');
            setNewCountryId('');
            setNewKValue('');
            setValidationError('');
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setValidationError('Die Liga existiert bereits.');
            } else {
                setError(err);
            }
        }
    };

    const handleDeleteLeague = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/leagues/${id}`);
            setLeagues(leagues.filter(league => league.id !== id));
            clearConfirmDelete();
        } catch (err) {
            setError(err);
        }
    };

    const handleEditLeague = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/leagues/${id}`, { name: editLeagueName, countryId: editCountryId, k_value: parseInt(editKValue) });
            setLeagues(leagues.map(league => league.id === id ? response.data : league));
            setEditLeagueId(null);
            setEditLeagueName('');
            setEditCountryId('');
            setEditKValue('');
        } catch (err) {
            setError(err);
        }
    };

    const clearConfirmDelete = () => {
        setConfirmDeleteId(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const handleConfirmDelete = (id) => {
        if (confirmDeleteId !== id) {
            clearConfirmDelete();
            setConfirmDeleteId(id);
            timerRef.current = setTimeout(clearConfirmDelete, 3000);
        } else {
            handleDeleteLeague(id);
        }
    };

    const handleKeyPress = (e, action) => {
        if (e.key === 'Enter') {
            action();
        }
    };

    const handleEditButtonClick = (league) => {
        setEditLeagueId(league.id);
        setEditLeagueName(league.name);
        setEditCountryId(league.country_id);
        setEditKValue(league.k_value);
    };

    const groupedLeagues = leagues.reduce((acc, league) => {
        const countryName = league.country;
        if (!acc[countryName]) {
            acc[countryName] = [];
        }
        acc[countryName].push(league);
        return acc;
    }, {});

    const sortedCountries = Object.keys(groupedLeagues).sort((a, b) => {
        if (a === 'International') return 1;
        if (b === 'International') return -1;
        if (groupedLeagues[b].length !== groupedLeagues[a].length) {
            return groupedLeagues[b].length - groupedLeagues[a].length;
        }
        return a.localeCompare(b);
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading leagues: {error.message}</p>;

    return (
        <div>
            <h1>Ligen</h1>
            <div className="league-card new-league">
                <input
                    type="text"
                    placeholder="Neue Liga hinzufügen"
                    value={newLeague}
                    onChange={(e) => setNewLeague(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, handleAddLeague)}
                />
                <select value={newCountryId} onChange={(e) => setNewCountryId(e.target.value)}>
                    <option value="">Land auswählen</option>
                    {countries.map(country => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </select>
                <input
                    type="number"
                    placeholder="K-Wert eingeben"
                    value={newKValue}
                    onChange={(e) => setNewKValue(e.target.value)}
                />
                <button onClick={handleAddLeague}>Hinzufügen</button>
                {validationError && <p className="error">{validationError}</p>}
            </div>
            <div className="league-list">
                {sortedCountries.map(countryName => (
                    <div key={countryName}>
                        <h2>{countryName}</h2>
                        {groupedLeagues[countryName].map(league => (
                            <div className="league-card" key={league.id}>
                                {editLeagueId === league.id ? (
                                    <div>
                                        <input
                                            type="text"
                                            value={editLeagueName}
                                            onChange={(e) => setEditLeagueName(e.target.value)}
                                            onKeyPress={(e) => handleKeyPress(e, () => handleEditLeague(league.id))}
                                        />
                                        <select value={editCountryId} onChange={(e) => setEditCountryId(e.target.value)}>
                                            <option value="">Land auswählen</option>
                                            {countries.map(country => (
                                                <option key={country.id} value={country.id}>{country.name}</option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            value={editKValue}
                                            onChange={(e) => setEditKValue(e.target.value)}
                                        />
                                        <button onClick={() => handleEditLeague(league.id)}>Speichern</button>
                                        <button onClick={() => { setEditLeagueId(null); setEditLeagueName(''); setEditCountryId(''); setEditKValue(''); }}>Abbrechen</button>
                                    </div>
                                ) : (
                                    <div>
                                        <h3>{league.name}</h3>
                                        <p className="k-value">K-Wert: {league.k_value}</p>
                                        <button onClick={() => handleEditButtonClick(league)}>Bearbeiten</button>
                                        <button onClick={() => handleConfirmDelete(league.id)}>
                                            {confirmDeleteId === league.id ? 'Sicher?' : 'Löschen'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SetLeagues;
