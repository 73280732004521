import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/SetTelegram.css';

const SetTelegram = () => {
    const [settings, setSettings] = useState({
        apiKey: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showApiKey, setShowApiKey] = useState(false); // State to toggle visibility of API key

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/telegram`);
                const settingsData = response.data.reduce((acc, item) => {
                    if (item.key === 'apiKey') {
                        acc.apiKey = item.value;
                    }
                    return acc;
                }, {
                    apiKey: ''
                });

                setSettings(settingsData);
                setLoading(false);
            } catch (error) {
                setError('Fehler beim Laden der Einstellungen');
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_SERVER}/telegram`, settings);
            alert('Einstellungen erfolgreich aktualisiert');
        } catch (error) {
            alert('Fehler beim Aktualisieren der Einstellungen');
        }
    };

    if (loading) return <p>Lädt...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="telegram-settings">
            <h1>Telegram Bot Einstellungen</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    API Schlüssel:
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                        <input
                            type={showApiKey ? 'text' : 'password'}
                            name="apiKey"
                            value={settings.apiKey}
                            onChange={handleChange}
                            style={{ width: '97%' }} // Add padding to accommodate the eye icon
                        />
                        <button
                            type="button"
                            onMouseDown={() => setShowApiKey(true)}
                            onMouseUp={() => setShowApiKey(false)}
                            onMouseLeave={() => setShowApiKey(false)} // Hide if mouse leaves the button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-75%)',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                padding: '0'
                            }}
                        >
                            👁️
                        </button>
                    </div>
                </label>
                <button type="submit">Speichern</button>
            </form>
        </div>
    );
};

export default SetTelegram;
