import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Matches.css';
import getMatches from '../functions/matches/getMatches';

const Bets = () => {
    const [matches, setMatches] = useState([]);
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [amount, setAmount] = useState('');
    const [bets, setBets] = useState({});

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                await getMatches(setMatches, setLoading, setError);

                const balanceData = await axios.get(`${process.env.REACT_APP_SERVER}/bets/balance`);
                setBalance(parseFloat(balanceData.data.balance));

                const savedBetsData = await axios.get(`${process.env.REACT_APP_SERVER}/bets/load-bets`);
                const savedBets = savedBetsData.data.reduce((acc, bet) => {
                    acc[bet.match_id] = bet;
                    return acc;
                }, {});
                setBets(savedBets);
            } catch (err) {
                console.error('Error loading initial data:', err); // Protokolliert den Fehler
                setError('Fehler beim Abrufen der Daten.');
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);


    const handleSaveBet = async (matchId) => {
        const bet = bets[matchId];
        if (bet && bet.quote && bet.stake) {
            try {
                await axios.post(`${process.env.REACT_APP_SERVER}/bets/save-bet`, {
                    match_id: matchId,
                    quote: bet.quote,
                    stake: bet.stake,
                    result: bet.result,
                    status: bet.status || 'open'
                });
                alert('Wette erfolgreich gespeichert!');
            } catch (err) {
                setError('Fehler beim Speichern der Wette.');
            }
        } else {
            alert('Bitte füllen Sie sowohl die Quote als auch den Einsatz aus.');
        }
    };

    const handleStatusChange = (matchId, status) => {
        setBets(prevBets => ({
            ...prevBets,
            [matchId]: {
                ...prevBets[matchId],
                status: status
            }
        }));
    };

    const handleQuoteChange = (matchId, quote) => {
        setBets(prevBets => ({
            ...prevBets,
            [matchId]: {
                ...prevBets[matchId],
                quote: quote
            }
        }));
    };

    const handleStakeChange = (matchId, stake) => {
        setBets(prevBets => ({
            ...prevBets,
            [matchId]: {
                ...prevBets[matchId],
                stake: stake
            }
        }));
    };

    const calculateNetProfit = (matchId) => {
        const bet = bets[matchId];
        if (bet && bet.quote && bet.stake) {
            const profit = bet.quote * bet.stake;
            const netProfit = profit - bet.stake;
            return netProfit.toFixed(2);
        }
        return '0.00';
    };

    const handleAddBalance = async () => {
        if (!amount) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/bets/update-balance`, {
                amount: parseFloat(amount),
                operation: 'increment'
            });
            setBalance(parseFloat(response.data.balance));
            setAmount('');
        } catch (err) {
            setError('Fehler beim Aktualisieren des Kontostands.');
        }
    };

    const handleSubtractBalance = async () => {
        if (!amount) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/bets/update-balance`, {
                amount: parseFloat(amount),
                operation: 'decrement'
            });
            setBalance(parseFloat(response.data.balance));
            setAmount('');
        } catch (err) {
            setError('Fehler beim Aktualisieren des Kontostands.');
        }
    };

    const filteredMatches = matches.filter(match => match.homechance >= 55 || match.awaychance >= 55);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    return (
        <div>
            <h1>Bets</h1>
            <div>
                <h2>Kontostand: {balance.toFixed(2)} €</h2>
                <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Betrag eingeben"
                />
                <button onClick={handleAddBalance}>+</button>
                <button onClick={handleSubtractBalance}>-</button>
            </div>
            <div className="match-table">
                <table>
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Uhrzeit</th>
                        <th>Heim</th>
                        <th>Auswärts</th>
                        <th>Land</th>
                        <th>Liga</th>
                        <th>Season</th>
                        <th>Spieltag</th>
                        <th>Heim-Chance</th>
                        <th>Auswärts-Chance</th>
                        <th>Quote</th>
                        <th>Einsatz</th>
                        <th>Nettogewinn</th>
                        <th>Ergebnis</th>
                        <th>Status</th>
                        <th>Speichern</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredMatches.sort((a, b) => new Date(a.matchdate) - new Date(b.matchdate)).map(match => (
                        <tr key={match.id}>
                            <td>{new Date(match.matchdate).toLocaleDateString()}</td>
                            <td>{match.matchtime}</td>
                            <td>{match.home_team}</td>
                            <td>{match.away_team}</td>
                            <td>{match.country}</td>
                            <td>{match.league}</td>
                            <td>{match.season}</td>
                            <td>{match.gameday}</td>
                            <td>{match.homechance}%</td>
                            <td>{match.awaychance}%</td>
                            <td>
                                <input
                                    type="number"
                                    value={bets[match.id]?.quote || ''}
                                    onChange={(e) => handleQuoteChange(match.id, e.target.value)}
                                    placeholder="Quote"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={bets[match.id]?.stake || ''}
                                    onChange={(e) => handleStakeChange(match.id, e.target.value)}
                                    placeholder="Einsatz"
                                />
                            </td>
                            <td>{calculateNetProfit(match.id)} €</td>
                            <td>{match.result || 'Kein Ergebnis'}</td>
                            <td>
                                <select
                                    value={bets[match.id]?.status || 'open'}
                                    onChange={(e) => handleStatusChange(match.id, e.target.value)}
                                >
                                    <option value="open">Offen</option>
                                    <option value="won">Gewonnen</option>
                                    <option value="lost">Verloren</option>
                                </select>
                            </td>
                            <td>
                                <button
                                    onClick={() => handleSaveBet(match.id)}
                                    disabled={!bets[match.id]?.quote || !bets[match.id]?.stake}
                                >
                                    Speichern
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Bets;
