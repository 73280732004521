import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';

const Dashboard = () => {
    const [countries, setCountries] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countriesResponse, leaguesResponse, teamsResponse, matchesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER}/countries`),
                    axios.get(`${process.env.REACT_APP_SERVER}/leagues`),
                    axios.get(`${process.env.REACT_APP_SERVER}/teams`),
                    axios.get(`${process.env.REACT_APP_SERVER}/matches`)
                ]);
                setCountries(countriesResponse.data);
                setLeagues(leaguesResponse.data);
                setTeams(teamsResponse.data);
                setMatches(matchesResponse.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    // Erstellen eines Sets, um doppelte Teams zu entfernen
    const uniqueTeams = [...new Set(teams.map(team => team.name))];

    return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            <div className="stats">
                <div className="stat-card">
                    <h2>Spiele</h2>
                    <p>{matches.length}</p>
                </div>
                <div className="stat-card">
                    <h2>Teams</h2>
                    <p>{uniqueTeams.length}</p>
                </div>
                <div className="stat-card">
                    <h2>Ligen</h2>
                    <p>{leagues.length}</p>
                </div>
                <div className="stat-card">
                    <h2>Länder</h2>
                    <p>{countries.length}</p>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
