import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../../styles/Teams.css'; // Optionale CSS-Datei für Styling

const SetTeams = () => {
    const [countries, setCountries] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [filteredLeagues, setFilteredLeagues] = useState([]);
    const [seasons, setseasons] = useState([]);
    const [filteredseasons, setFilteredseasons] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newTeam, setNewTeam] = useState('');
    const [newCountryId, setNewCountryId] = useState('');
    const [newLeagueId, setNewLeagueId] = useState('');
    const [newseasonId, setNewseasonId] = useState('');
    const [filterCountryId, setFilterCountryId] = useState('');
    const [filterLeagueId, setFilterLeagueId] = useState('');
    const [filterseasonId, setFilterseasonId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [validationError, setValidationError] = useState('');
    const [editTeamId, setEditTeamId] = useState(null);
    const [editTeamName, setEditTeamName] = useState('');
    const [editseasons, setEditseasons] = useState({});
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const timerRef = useRef(null);

    useEffect(() => {
        const fetchCountriesLeaguesseasonsAndTeams = async () => {
            try {
                const [countriesResponse, leaguesResponse, seasonsResponse, teamsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER}/countries`),
                    axios.get(`${process.env.REACT_APP_SERVER}/leagues`),
                    axios.get(`${process.env.REACT_APP_SERVER}/seasons`),
                    axios.get(`${process.env.REACT_APP_SERVER}/teams`)
                ]);
                setCountries(countriesResponse.data);
                setLeagues(leaguesResponse.data);
                setseasons(seasonsResponse.data);
                setTeams(teamsResponse.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching initial data:', err);
                setError(err);
                setLoading(false);
            }
        };

        fetchCountriesLeaguesseasonsAndTeams();
    }, []);

    useEffect(() => {
        if (newCountryId) {
            const filtered = leagues.filter(league => league.country_id === parseInt(newCountryId));
            setFilteredLeagues(filtered);
            setFilteredseasons([]);
            setNewLeagueId('');
            setNewseasonId('');
        } else {
            setFilteredLeagues([]);
            setFilteredseasons([]);
            setNewLeagueId('');
            setNewseasonId('');
        }
    }, [newCountryId, leagues]);

    useEffect(() => {
        if (newLeagueId) {
            const filtered = seasons.filter(season => season.league_id === parseInt(newLeagueId));
            setFilteredseasons(filtered);
            setNewseasonId('');
        } else {
            setFilteredseasons([]);
            setNewseasonId('');
        }
    }, [newLeagueId, seasons]);

    useEffect(() => {
        if (filterCountryId) {
            const filteredLeagues = leagues.filter(league => league.country_id === parseInt(filterCountryId));
            setFilteredLeagues(filteredLeagues);
            setFilterLeagueId('');
            setFilterseasonId('');
            setFilteredseasons([]);
        } else {
            setFilteredLeagues([]);
            setFilterLeagueId('');
            setFilterseasonId('');
            setFilteredseasons([]);
        }
    }, [filterCountryId, leagues]);

    useEffect(() => {
        if (filterLeagueId) {
            const filteredseasons = seasons.filter(season => season.league_id === parseInt(filterLeagueId));
            setFilteredseasons(filteredseasons);
            setFilterseasonId('');
        } else {
            setFilteredseasons([]);
            setFilterseasonId('');
        }
    }, [filterLeagueId, seasons]);

    const handleAddTeam = async () => {
        if (!newTeam.trim() || !newCountryId || !newLeagueId || !newseasonId) {
            setValidationError('Alle Felder müssen ausgefüllt sein.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/teams`, {
                name: newTeam,
                seasonId: newseasonId
            });
            setTeams([...teams, response.data]);
            setNewTeam('');
            setNewCountryId('');
            setNewLeagueId('');
            setNewseasonId('');
            setValidationError('');
            resetFilters();
        } catch (err) {
            console.error('Error adding team:', err);
            if (err.response && err.response.status === 409) {
                setValidationError('Das Team existiert bereits.');
            } else {
                setError(err);
            }
        }
    };

    const resetFilters = () => {
        setFilterCountryId('');
        setFilterLeagueId('');
        setFilterseasonId('');
        setFilteredLeagues([]);
        setFilteredseasons([]);
    };

    const handleDeleteTeam = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/teams/${id}`);
            setTeams(teams.filter(team => team.id !== id));
            clearConfirmDelete();
        } catch (err) {
            console.error('Error deleting team:', err);
            setError(err);
        }
    };

    const handleEditseason = async (season) => {
        console.log('Editing season:', season);
        try {
            await axios.put(`${process.env.REACT_APP_SERVER}/teams/${season.id}`, {
                name: editTeamName,
                seasonId: season.seasonId,
                leagueId: season.leagueId
            });

            const response = await axios.get(`${process.env.REACT_APP_SERVER}/teams`);
            console.log('Updated teams response:', response.data);
            setTeams(response.data);
            setEditTeamId(null);
            setEditTeamName('');
            setEditseasons({});
        } catch (err) {
            console.error('Error editing season:', err);
            setError(err);
        }
    };

    const clearConfirmDelete = () => {
        setConfirmDeleteId(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const handleConfirmDelete = (id) => {
        if (confirmDeleteId !== id) {
            clearConfirmDelete();
            setConfirmDeleteId(id);
            timerRef.current = setTimeout(clearConfirmDelete, 3000);
        } else {
            handleDeleteTeam(id);
        }
    };

    const handleKeyPress = (e, action) => {
        if (e.key === 'Enter') {
            action();
        }
    };

    const handleEditButtonClick = (team) => {
        console.log('Editing team:', team);
        setEditTeamId(team.id);
        setEditTeamName(team.name);
        setEditseasons({
            [team.id]: team.seasons.map(season => ({
                id: season.id,
                seasonId: season.seasonId,
                leagueId: season.leagueId,
                seasonName: season.seasonName,
                leagueName: season.leagueName
            }))
        });

        // Set filtered leagues based on the country of the team
        const countryId = team.country_id;
        const filtered = leagues.filter(league => league.country_id === countryId);
        setFilteredLeagues(filtered);
    };

    const handleEditseasonChange = (teamId, seasonIndex, field, value) => {
        console.log('Changing season:', { teamId, seasonIndex, field, value });
        const updatedseasons = [...editseasons[teamId]];
        updatedseasons[seasonIndex] = {
            ...updatedseasons[seasonIndex],
            [field]: value
        };
        setEditseasons({
            ...editseasons,
            [teamId]: updatedseasons
        });

        // Update filteredseasons based on the selected leagueId
        if (field === 'leagueId') {
            const filtered = seasons.filter(season => season.league_id === parseInt(value));
            setFilteredseasons(filtered);
        }
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const filteredTeams = teams.filter(team => {
        if (searchTerm) {
            const searchTermLower = searchTerm.toLowerCase();
            return (
                team.name.toLowerCase().includes(searchTermLower) ||
                team.country.toLowerCase().includes(searchTermLower) ||
                team.league.toLowerCase().includes(searchTermLower) ||
                team.season.toLowerCase().includes(searchTermLower)
            );
        }
        if (filterseasonId) {
            return team.season_id === parseInt(filterseasonId);
        }
        if (filterLeagueId) {
            return team.league_id === parseInt(filterLeagueId);
        }
        if (filterCountryId) {
            return team.country_id === parseInt(filterCountryId);
        }
        return true;
    });

    const groupedTeams = filteredTeams.reduce((acc, team) => {
        const teamName = team.name;
        if (!acc[teamName]) {
            acc[teamName] = {
                ...team,
                seasons: []
            };
        }
        acc[teamName].seasons.push({
            id: team.id,
            seasonId: team.season_id,
            seasonName: team.season,
            leagueId: team.league_id,
            leagueName: team.league
        });
        return acc;
    }, {});

    const sortedTeams = Object.values(groupedTeams).sort((a, b) => a.name.localeCompare(b.name));

    const groupedByCountry = sortedTeams.reduce((acc, team) => {
        const countryName = team.country;
        if (!acc[countryName]) {
            acc[countryName] = [];
        }
        acc[countryName].push(team);
        return acc;
    }, {});

    const sortedCountries = Object.keys(groupedByCountry).sort((a, b) => a.localeCompare(b));

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading teams: {error.message}</p>;

    return (
        <div>
            <h1>Mannschaften</h1>
            <div className="team-card new-team">
                <input
                    type="text"
                    placeholder="Neue Mannschaft hinzufügen"
                    value={newTeam}
                    onChange={(e) => setNewTeam(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, handleAddTeam)}
                />
                <select value={newCountryId} onChange={(e) => setNewCountryId(e.target.value)}>
                    <option value="">Land auswählen</option>
                    {countries.map(country => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </select>
                <select value={newLeagueId} onChange={(e) => setNewLeagueId(e.target.value)} disabled={!newCountryId}>
                    <option value="">Liga auswählen</option>
                    {filteredLeagues.map(league => (
                        <option key={league.id} value={league.id}>{league.name}</option>
                    ))}
                </select>
                <select value={newseasonId} onChange={(e) => setNewseasonId(e.target.value)} disabled={!newLeagueId}>
                    <option value="">Season auswählen</option>
                    {filteredseasons.map(season => (
                        <option key={season.id} value={season.id}>{season.name}</option>
                    ))}
                </select>
                <button onClick={handleAddTeam}>Hinzufügen</button>
                {validationError && <p className="error">{validationError}</p>}
            </div>
            <div className="filter">
                <h2>Filter</h2>
                <div className="filter-container">
                    <div className="filter-group">
                        <select id="filterCountry" value={filterCountryId}
                                onChange={(e) => setFilterCountryId(e.target.value)}>
                            <option value="">Land auswählen</option>
                            {countries.map(country => (
                                <option key={country.id} value={country.id}>{country.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-group">
                        <select id="filterLeague" value={filterLeagueId}
                                onChange={(e) => setFilterLeagueId(e.target.value)} disabled={!filterCountryId}>
                            <option value="">Liga auswählen</option>
                            {filteredLeagues.map(league => (
                                <option key={league.id} value={league.id}>{league.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-group">
                        <select id="filterseason" value={filterseasonId}
                                onChange={(e) => setFilterseasonId(e.target.value)} disabled={!filterLeagueId}>
                            <option value="">Season auswählen</option>
                            {filteredseasons.map(season => (
                                <option key={season.id} value={season.id}>{season.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="search">
                    <input
                        type="text"
                        placeholder="Suchen..."
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
            </div>
            <div className="team-list">
                {sortedCountries.map(countryName => (
                    <div key={countryName}>
                        <h2>{countryName}</h2>
                        <div className="teams-container">
                            {groupedByCountry[countryName].map(team => (
                                <div className="team-card" key={team.id}>
                                    <h3>{team.name}</h3>
                                    {team.seasons.map((season, index) => (
                                        <div key={season.id}>
                                            {editTeamId === team.id && editseasons[team.id][index] ? (
                                                <div>
                                                    <select
                                                        value={editseasons[team.id][index].leagueId}
                                                        onChange={(e) => handleEditseasonChange(team.id, index, 'leagueId', e.target.value)}
                                                    >
                                                        <option value="">Liga auswählen</option>
                                                        {leagues.filter(l => l.country_id === team.country_id).map(l => (
                                                            <option key={l.id} value={l.id}>{l.name}</option>
                                                        ))}
                                                    </select>
                                                    <select
                                                        value={editseasons[team.id][index].seasonId}
                                                        onChange={(e) => handleEditseasonChange(team.id, index, 'seasonId', e.target.value)}
                                                        disabled={!editseasons[team.id][index].leagueId}
                                                    >
                                                        <option value="">season auswählen</option>
                                                        {seasons.filter(s => s.league_id === parseInt(editseasons[team.id][index].leagueId)).map(s => (
                                                            <option key={s.id} value={s.id}>{s.name}</option>
                                                        ))}
                                                    </select>
                                                    <button onClick={() => handleEditseason(editseasons[team.id][index])}>Speichern</button>
                                                </div>
                                            ) : (
                                                <p>{season.seasonName} - {season.leagueName}</p>
                                            )}
                                        </div>
                                    ))}
                                    {editTeamId === team.id ? (
                                        <div>
                                            <button onClick={() => { setEditTeamId(null); setEditTeamName(''); setEditseasons({}); }}>Abbrechen</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <button onClick={() => handleEditButtonClick(team)}>Bearbeiten</button>
                                            <button onClick={() => handleConfirmDelete(team.id)}>
                                                {confirmDeleteId === team.id ? 'Sicher?' : 'Löschen'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SetTeams;
