import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../../styles/Countries.css'; // Optionale CSS-Datei für Styling

const SetCountries = () => {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newCountry, setNewCountry] = useState('');
    const [validationError, setValidationError] = useState('');
    const [editCountryId, setEditCountryId] = useState(null);
    const [editCountryName, setEditCountryName] = useState('');
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const timerRef = useRef(null);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/countries`);
                setCountries(response.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchCountries();
    }, []);

    const handleAddCountry = async () => {
        if (!newCountry.trim()) {
            setValidationError('Das Feld darf nicht leer sein.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/countries`, { name: newCountry });
            setCountries([...countries, response.data]);
            setNewCountry('');
            setValidationError('');
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setValidationError('Das Land existiert bereits.');
            } else {
                setError(err);
            }
        }
    };

    const handleDeleteCountry = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/countries/${id}`);
            setCountries(countries.filter(country => country.id !== id));
            clearConfirmDelete();
        } catch (err) {
            setError(err);
        }
    };

    const handleEditCountry = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/countries/${id}`, { name: editCountryName });
            setCountries(countries.map(country => country.id === id ? response.data : country));
            setEditCountryId(null);
            setEditCountryName('');
        } catch (err) {
            setError(err);
        }
    };

    const clearConfirmDelete = () => {
        setConfirmDeleteId(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const handleConfirmDelete = (id) => {
        if (confirmDeleteId !== id) {
            clearConfirmDelete();
            setConfirmDeleteId(id);
            timerRef.current = setTimeout(clearConfirmDelete, 3000);
        } else {
            handleDeleteCountry(id);
        }
    };

    const handleKeyPress = (e, action) => {
        if (e.key === 'Enter') {
            action();
        }
    };

    const sortedCountries = countries.sort((a, b) => {
        if (a.name === 'International') return 1;
        if (b.name === 'International') return -1;
        return a.name.localeCompare(b.name);
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading countries: {error.message}</p>;

    return (
        <div>
            <h1>Länder</h1>
            <div className="country-card new-country">
                <input
                    type="text"
                    placeholder="Neues Land hinzufügen"
                    value={newCountry}
                    onChange={(e) => setNewCountry(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, handleAddCountry)}
                />
                <button onClick={handleAddCountry}>Hinzufügen</button>
                {validationError && <p className="error">{validationError}</p>}
            </div>
            <div className="country-list">
                {Array.isArray(sortedCountries) && sortedCountries.map(country => (
                    <div className="country-card" key={country.id}>
                        {editCountryId === country.id ? (
                            <div>
                                <input
                                    type="text"
                                    value={editCountryName}
                                    onChange={(e) => setEditCountryName(e.target.value)}
                                    onKeyPress={(e) => handleKeyPress(e, () => handleEditCountry(country.id))}
                                />
                                <button onClick={() => handleEditCountry(country.id)}>Speichern</button>
                                <button onClick={() => setEditCountryId(null)}>Abbrechen</button>
                            </div>
                        ) : (
                            <div>
                                <h2>{country.name}</h2>
                                <button onClick={() => { setEditCountryId(country.id); setEditCountryName(country.name); }}>Bearbeiten</button>
                                <button onClick={() => handleConfirmDelete(country.id)}>
                                    {confirmDeleteId === country.id ? 'Sicher?' : 'Löschen'}
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SetCountries;
