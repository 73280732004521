import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../../styles/Seasons.css'; // Optionale CSS-Datei für Styling

const SetSeasons = () => {
    const [countries, setCountries] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [filteredLeagues, setFilteredLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newSeason, setNewSeason] = useState('');
    const [newCountryId, setNewCountryId] = useState('');
    const [newLeagueId, setNewLeagueId] = useState('');
    const [validationError, setValidationError] = useState('');
    const [editSeasonId, setEditSeasonId] = useState(null);
    const [editSeasonName, setEditSeasonName] = useState('');
    const [editCountryId, setEditCountryId] = useState('');
    const [editLeagueId, setEditLeagueId] = useState('');
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const [confirmResetId, setConfirmResetId] = useState(null); // New state for reset confirmation
    const timerRef = useRef(null);

    useEffect(() => {
        const fetchCountriesLeaguesAndseasons = async () => {
            try {
                const [countriesResponse, leaguesResponse, seasonsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_SERVER}/countries`),
                    axios.get(`${process.env.REACT_APP_SERVER}/leagues`),
                    axios.get(`${process.env.REACT_APP_SERVER}/seasons`)
                ]);
                setCountries(countriesResponse.data);
                setLeagues(leaguesResponse.data);
                setSeasons(seasonsResponse.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchCountriesLeaguesAndseasons();
    }, []);

    useEffect(() => {
        if (newCountryId) {
            const filtered = leagues.filter(league => league.country_id === parseInt(newCountryId));
            setFilteredLeagues(filtered);
        } else {
            setFilteredLeagues([]);
        }
    }, [newCountryId, leagues]);

    const handleAddseason = async () => {
        if (!newSeason.trim() || !newLeagueId) {
            setValidationError('Das Feld darf nicht leer sein und eine Liga muss ausgewählt werden.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/seasons`, { name: newSeason, leagueId: newLeagueId });
            setSeasons([...seasons, response.data]);
            setNewSeason('');
            setNewCountryId('');
            setNewLeagueId('');
            setValidationError('');
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setValidationError('Die season existiert bereits.');
            } else {
                setError(err);
            }
        }
    };

    const handleDeleteSeason = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER}/seasons/${id}`);
            setSeasons(seasons.filter(season => season.id !== id));
            clearConfirmDelete();
        } catch (err) {
            setError(err);
        }
    };

    const handleEditSeason = async (id) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/seasons/${id}`, { name: editSeasonName, leagueId: editLeagueId });
            setSeasons(seasons.map(season => season.id === id ? response.data : season));
            setEditSeasonId(null);
            setEditSeasonName('');
            setEditCountryId('');
            setEditLeagueId('');
        } catch (err) {
            setError(err);
        }
    };

    const handleResetSeason = async (id) => {
        try {
            await axios.put(`${process.env.REACT_APP_SERVER}/seasons/${id}/reset`);
            // Optionally, you could refresh the seasons or matches here if needed
            clearConfirmReset();
        } catch (err) {
            setError(err);
        }
    };

    const clearConfirmDelete = () => {
        setConfirmDeleteId(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const clearConfirmReset = () => { // New function to clear reset confirmation
        setConfirmResetId(null);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const handleConfirmDelete = (id) => {
        if (confirmDeleteId !== id) {
            clearConfirmDelete();
            setConfirmDeleteId(id);
            timerRef.current = setTimeout(clearConfirmDelete, 3000);
        } else {
            handleDeleteSeason(id);
        }
    };

    const handleConfirmReset = (id) => { // New function to handle reset confirmation
        if (confirmResetId !== id) {
            clearConfirmReset();
            setConfirmResetId(id);
            timerRef.current = setTimeout(clearConfirmReset, 3000);
        } else {
            handleResetSeason(id);
        }
    };

    const handleKeyPress = (e, action) => {
        if (e.key === 'Enter') {
            action();
        }
    };

    const handleEditButtonClick = (season) => {
        setEditSeasonId(season.id);
        setEditSeasonName(season.name);
        setEditCountryId(season.country_id);
        setEditLeagueId(season.league_id);
    };

    const groupedSeasons = seasons.reduce((acc, season) => {
        const countryName = season.country;
        if (!acc[countryName]) {
            acc[countryName] = [];
        }
        acc[countryName].push(season);
        return acc;
    }, {});

    const sortedCountries = Object.keys(groupedSeasons).sort((a, b) => {
        if (groupedSeasons[b].length !== groupedSeasons[a].length) {
            return groupedSeasons[b].length - groupedSeasons[a].length;
        }
        return a.localeCompare(b);
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading seasons: {error.message}</p>;

    return (
        <div>
            <h1>Seasons</h1>
            <div className="season-card new-season">
                <input
                    type="text"
                    placeholder="Neue Season hinzufügen"
                    value={newSeason}
                    onChange={(e) => setNewSeason(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, handleAddseason)}
                />
                <select value={newCountryId} onChange={(e) => setNewCountryId(e.target.value)}>
                    <option value="">Land auswählen</option>
                    {countries.map(country => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </select>
                <select value={newLeagueId} onChange={(e) => setNewLeagueId(e.target.value)} disabled={!newCountryId}>
                    <option value="">Liga auswählen</option>
                    {filteredLeagues.map(league => (
                        <option key={league.id} value={league.id}>{league.name}</option>
                    ))}
                </select>
                <button onClick={handleAddseason}>Hinzufügen</button>
                {validationError && <p className="error">{validationError}</p>}
            </div>
            <div className="season-list">
                {sortedCountries.map(countryName => (
                    <div key={countryName}>
                        <h2>{countryName}</h2>
                        {groupedSeasons[countryName].reduce((acc, season) => {
                            if (!acc.some(item => item.league_id === season.league_id)) {
                                acc.push({
                                    league_id: season.league_id,
                                    league_name: season.league
                                });
                            }
                            return acc;
                        }, []).map(league => (
                            <div key={league.league_id}>
                                <h3>{league.league_name}</h3>
                                {groupedSeasons[countryName].filter(season => season.league_id === league.league_id).map(season => (
                                    <div className="season-card" key={season.id}>
                                        {editSeasonId === season.id ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    value={editSeasonName}
                                                    onChange={(e) => setEditSeasonName(e.target.value)}
                                                    onKeyPress={(e) => handleKeyPress(e, () => handleEditSeason(season.id))}
                                                />
                                                <select value={editCountryId} onChange={(e) => setEditCountryId(e.target.value)}>
                                                    <option value="">Land auswählen</option>
                                                    {countries.map(country => (
                                                        <option key={country.id} value={country.id}>{country.name}</option>
                                                    ))}
                                                </select>
                                                <select value={editLeagueId} onChange={(e) => setEditLeagueId(e.target.value)} disabled={!editCountryId}>
                                                    <option value="">Liga auswählen</option>
                                                    {leagues.filter(league => league.country_id === parseInt(editCountryId)).map(league => (
                                                        <option key={league.id} value={league.id}>{league.name}</option>
                                                    ))}
                                                </select>
                                                <button onClick={() => handleEditSeason(season.id)}>Speichern</button>
                                                <button onClick={() => { setEditSeasonId(null); setEditSeasonName(''); setEditCountryId(''); setEditLeagueId(''); }}>Abbrechen</button>
                                            </div>
                                        ) : (
                                            <div>
                                                <h3>{season.name}</h3>
                                                <button onClick={() => handleEditButtonClick(season)}>Bearbeiten</button>
                                                <button onClick={() => handleConfirmDelete(season.id)}>
                                                    {confirmDeleteId === season.id ? 'Sicher?' : 'Löschen'}
                                                </button>
                                                <button onClick={() => handleConfirmReset(season.id)}> {/* New button for reset */}
                                                    {confirmResetId === season.id ? 'Sicher?' : 'Zurücksetzen'}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SetSeasons;
